/* Branding fonts for benchmarking+ starting page */
@font-face {
  font-family: 'Gordita';
  font-style: normal;
  font-weight: 500;
  src: url(/public/assets/fonts/Gordita-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Gordita';
  font-style: normal;
  font-weight: 300;
  src: url(/public/assets/fonts/Gordita-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'Gordita';
  font-style: normal;
  font-weight: 700;
  src: url(/public/assets/fonts/Gordita-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Gordita';
  font-style: normal;
  font-weight: normal;
  src: url(/public/assets/fonts/Gordita-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Gordita';
  font-style: normal;
  font-weight: 900;
  src: url(/public/assets/fonts/Gordita-Black.woff2) format('woff2');
}

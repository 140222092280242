.react-grid-item.react-grid-placeholder {
  background-color: transparent;
}

.react-grid-item.react-grid-placeholder::after {
  content: '';
  position: absolute;
  height: calc(100% - 12px);
  width: calc(100% - 12px);
  margin-left: 6px;
  margin-top: 6px;
  border: 2px dashed black;
  box-sizing: border-box;
}

.react-grid-item > .react-resizable-handle {
  background-image: none;
}

.react-grid-item > .react-resizable-handle::after {
  right: 9px;
  bottom: 9px;
  width: 10px;
  height: 10px;
  content: ' ';
  position: absolute;
  border-right: 2px solid rgb(0 0 0);
  border-bottom: 2px solid rgb(0 0 0);
}
